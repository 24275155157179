export const REACT_APP_REGION = process.env.REACT_APP_REGION;
export const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const REACT_APP_GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const REACT_APP_API_NAME = process.env.REACT_APP_API_NAME

export const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID


export const AMPLIFY_AUTH = {
  identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
};