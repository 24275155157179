import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";
import { put } from "@redux-saga/core/effects";
import { showAuthMessage } from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">

      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );


  useEffect(() => {
    profilefetch()
    img()
  }, []);

  const profilefetch = async () => {
    // if (userprofile.response_code === "FAILED") {
    //   put(showAuthMessage(userprofile.message));
    // } else {
    //   setprofile(userprofile)
    // }
  }
  const [profile, setprofile] = useState({})
  const [profileimg, setProfileimg] = useState('')
  const img = () => {
    var data = [
      "https://avatars.dicebear.com/api/adventurer-neutral/mail%40ashallendesign.co.uk.svg"
    ]
    var item = data[Math.floor(Math.random() * data.length)];
    setProfileimg(item)
  }
  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <a target="_blank" href={profileimg}>
        <Avatar
          src={profileimg}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt="" />
      </a>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-avatar-name">{profile.username}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
