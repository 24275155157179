import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";


const Admin = ({ match }) => (
    <div className="gx-main-content-wrapper">
        <Switch>
            <Route path={`${match.url}applications`} exact component={asyncComponent(() => import('./applications/Applications'))} />
            <Route path={`${match.url}users`} exact component={asyncComponent(() => import('./users/Users'))} />
            <Route path={`${match.url}application/:id`} exact component={asyncComponent(() => import('./application/Application'))} />
        </Switch>
    </div>
);

export default Admin;
