

import Amplify from "@aws-amplify/core"

import { AMPLIFY_AUTH, REACT_APP_REGION, REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_API_NAME } from './config/aws-config.js';

export function configureAmplify() {
  Amplify.configure({
    Auth: AMPLIFY_AUTH,
    API: {
      endpoints: [
        {
          name: REACT_APP_API_NAME,
          endpoint: REACT_APP_GRAPHQL_ENDPOINT,
          region: REACT_APP_REGION,
          paths: ['/']
        }
      ]
    }
  })
}
